import * as Backbone from 'backbone';
import * as _ from 'underscore';

import './Footer.less';
import {CssClasses} from '../../../Constants/Constants';

const Selectors = {
    el: '.Footer',
    link: '.Footer-link'
};

const BindFunctions = [
    'setActiveRoute'
];

export default class Footer extends Backbone.View {
    constructor() {
        super({el: Selectors.el});
        _.bindAll(this, BindFunctions);
    }

    setActiveRoute(activeItemId) {
        const $activeItem = this.$(`${Selectors.link}[data-id=${activeItemId}]`);
        $activeItem.addClass(CssClasses.active);
    }
}
