import * as Backbone from 'backbone';
import * as _ from 'underscore';

import {CssClasses} from 'front/Constants/Constants';
import {ViewportEvents} from 'front/Constants/Constants';

import './TopNavMenu.less';

const Selectors = {
    el: '.TopNavMenu',
    link: '.TopNavMenu-link'
};

const BindFunctions = [
    'hideMenu'
];

export default class TopNavMenu extends Backbone.View {
    events() {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.hideMenu
        };
    }

    constructor() {
        super({el: Selectors.el});
        _.bindAll(this, BindFunctions);
    }

    toggleMenu(isOpen) {
        isOpen ? app.vent.trigger('overlay-shown', this.$el) : app.vent.trigger('overlay-hidden', this.$el);
        this.$el.toggleClass(CssClasses.show, isOpen);
    }

    setActiveRoute(activeItemId) {
        const $activeItem = this.$(`${Selectors.link}[data-id=${activeItemId}]`);
        $activeItem.addClass(CssClasses.active);
    }

    hideMenu() {
        window.app.views.topNav.collapseHamburger();
        this.toggleMenu(false);
    }
}
