export default class Utils {
    static createEmptyPromise() {
        return new Promise(function (resolve) {
            resolve();
        });
    }

    setActiveRoute(route) {
        window.app.views.topNav.setActiveRoute(route);
        window.app.views.footer.setActiveRoute(route);
        window.app.views.topNavMenu.setActiveRoute(route);
    }

    isPhoneLayout() {
        return window.matchMedia('screen and (max-width: 559px)').matches;
    }

    // getComponent(module) {
    //     return
    // }
}
