export const Constants = {
    selectors: {
        topNav: '.TopNav'
    }
};

export const ViewportEvents = {
    click: 'click',
    mouseEnter: 'mouseenter',
    mouseLeave: 'mouseleave',
    focus: 'focus',
    blur: 'blur',
    input: 'input',
    keyUp: 'keyup',
    change: 'change',
    mouseOver: 'mouseover',
    touchStart: 'touchstart',
    touchMove: 'touchmove',
    touchEnd: 'touchend',
    resize: 'resize',
    scroll: 'scroll',
    orientationChange: 'orientationchange',
    keyDown: 'keydown',
    submit: 'submit',
    popState: 'popstate',
    load: 'load'
};

export const BackboneEvents = {
    windowLoaded: 'window-loaded'
};

export const CssClasses = {
    show: 'show',
    hide: 'hide',
    active: 'active',
    messageSent: 'messageSent'
};

export const CssInlineProperties = {
    height: 'height'
};

export const Properties = {
    required: 'required',
    name: 'name'
};
