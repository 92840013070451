import * as Backbone from 'backbone';
import * as _ from 'underscore';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images/dist/ofi.common-js.js';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock/lib/bodyScrollLock';
import Promise from 'promise-polyfill';

import Utils from 'front/utils/Utils.js';
import CheckDevice from 'front/utils/CheckDevice/CheckDevice';
import MobileDetect from 'mobile-detect';
import Router from './Router';
import Settings from './Settings';
import {ViewportEvents, CssClasses, BackboneEvents} from './Constants/Constants';

import TopNav from './components/Common/TopNav/TopNav';
import Footer from './components/Common/Footer/Footer';
import TopNavMenu from './components/Common/TopNavMenu/TopNavMenu';

import 'slick-carousel';
import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';

import 'reset.css';
import 'front/fonts.less';
import 'front/utils.less';
import 'front/style.less';

const WindowIntersectionObserver = 'IntersectionObserver';

// To add to Promise to window for IE
if (!window.Promise) {
    window.Promise = Promise;
}

/* Init polyfills */

// object-fit property support for IE
objectFitImages();

// Sprite support for IE
svg4everybody({
    polyfill: true
});

const settings = new Settings();
app.configure = settings.configure;
app.configure();

window.app.vent = _.extend({}, Backbone.Events);

window.app.cache = {};
window.app.utils = new Utils();

if (window.app.state.googleMapsAPIReady) {
    window.app.vent.trigger('google-maps-ready');
}

new CheckDevice();

const mobileDetect = new MobileDetect(window.navigator.userAgent);
const phone = mobileDetect.phone();
const tablet = mobileDetect.tablet();

window.app.settings.isDesktop = !phone && !tablet;
window.app.settings.isMobile = phone || tablet;
window.app.settings.isTablet = !!tablet;
window.app.settings.isPhone = !!phone;

document.documentElement.className += ' ' + (app.settings.isDesktop ? 'isDesktop' : 'isNotDesktop');
document.documentElement.className += ' ' + (!app.settings.isDesktop ? 'isMobile' : 'isNotMobile');
document.documentElement.className += ' ' + (app.settings.isTablet ? 'isTablet' : 'isNotTablet');
document.documentElement.className += ' ' + (app.settings.isPhone ? 'isPhone' : 'isNotPhone');

// Here we declare root components that are rendered on server
// and exist all the time
window.app.views = {
    topNav: new TopNav(),
    footer: new Footer(),
    topNavMenu: new TopNavMenu()
};

// Global storage for frequently used DOM elements
// Example usage in views: app.els.$body
window.app.els = {
    $window: $(window),
    $body: $('body'),
    $htmlBody: $('html,body'),
    $content: $('.Content-body')
};


// Enabling frontend routing
app.els.$body.on('click', '.u-Route', function (e) {
    if (app.els.$body.hasClass('Custom404Page')) {
        return;
    }

    // var currentRoute = Backbone.history.getFragment();
    var url = $(e.currentTarget)
        .attr('href') || $(e.target)
        .closest('a')
        .attr('href');

    e.preventDefault();
    Backbone.history.navigate(url, {trigger: 'true'});
});

app.shareFacebook = function (data) {
    window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(data.link),
        'facebooksharedialog',
        'width=626,height=436');
};


app.shareTwitter = function (data) {
    var tweetUrl = 'https://twitter.com/share' +
        '?url=' + encodeURIComponent(data.link) +
        '&text=' + encodeURIComponent(data.title);

    window.open(tweetUrl, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
};


app.shareLinked = function (data) {
    window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(data.link) + '&title=' + encodeURIComponent(data.title) + '&summary=' + encodeURIComponent(data.title)/* + '&source=site.com'*/, 'sharer', 'toolbar=0, status=0, width=626, height=436');
};

app.router = new Router();

app.router.start();

app.vent.on('overlay-shown', ($elem) => {
    disableBodyScroll($elem[0]);
});

app.vent.on('overlay-hidden', ($elem) => {
    enableBodyScroll($elem[0]);
});

$('.anchor-link')
    .on('click', function (event) {
        const isAnchorOnPage = $(this.hash).length;
        if (isAnchorOnPage) {
            event.preventDefault();
        }
        $('html,body')
            .animate({
                scrollTop: $(this.hash)
                    .offset().top
            }, 500);
    });

const $el = $('[data-fade-in-on-scroll]');

if (WindowIntersectionObserver in window) {
    const imageObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                const item = entry.target;
                const $item = $(item);
                setTimeout(function () {
                    $item
                        .addClass(CssClasses.show)
                        .removeClass(CssClasses.hide);
                }, 300);
                imageObserver.unobserve(item);
            }
        });
    });

    $el.each(function (index) {
        imageObserver.observe($el[index]);
    });
} else {
    const resizeScrollOrientationChange =
        `${ViewportEvents.resize} ${ViewportEvents.scroll} ${ViewportEvents.orientationChange}`;
    const checkVisible = _.throttle(function () {
        $el.each(function (index, elem) {
            const $elem = $(elem);
            if ($elem.offset().top < (window.app.els.$window
                .height() + window.app.els.$window
                .scrollTop())) {
                setTimeout(function () {
                    $elem
                        .addClass(CssClasses.show)
                        .removeClass(CssClasses.hide);
                }, 300);
                window.app.els.$window
                    .off(resizeScrollOrientationChange, checkVisible);
            }
        });
    }, 100);

    window.app.els.$window
        .on(resizeScrollOrientationChange, () => {
            checkVisible();
        });

    checkVisible();
}

const $fadeInComponents = $(
    '[data-fade-in-on-load], ' +
    '.s-page-title--fade, ' +
    '.s-page-content--fade, ' +
    '.s-custom-image--fade');

$(window)
    .on(ViewportEvents.load, () => {
        $('.Content').css('visibility', '');
        $fadeInComponents
            .addClass(CssClasses.show)
            .removeClass(CssClasses.hide);

        window.app.vent.trigger(BackboneEvents.windowLoaded);
    });
