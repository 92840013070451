import {throttle} from 'underscore';

import './CheckDevice.less';

export default class CheckDevice {
    initialize() {
        const html = document.querySelector('html');
        this.viewport = document.getElementById('viewport');

        const isHighDensity = this.isHighDensity();

        if (isHighDensity) {
            window.app.isRetina = true;
            html.className = html.className + ' isRetina';
        }

        const isIos = this.isIos();

        window.app.isIos = isIos;
        if (isIos) {
            html.className = html.className + ' isIos';
        }

        const isSafari = this.isSafari();

        window.app.isSafari = isSafari;
        if (isSafari) {
            html.className = html.className + ' isSafari';
        }

        const isIE = this.isIE();
        window.app.isIE = isIE;
        if (isIE) {
            html.className = html.className + ' isIE';
        }

        const isTouch = this.isTouch();
        window.app.isTouch = isTouch;
        if (isTouch) {
            html.className = html.className + ' isTouch';
        } else {
            html.className = html.className + ' isNotTouch';
        }

        window.app.deviceType = this.checkDeviceType();

        window.addEventListener('resize', throttle(() => {
            const deviceType = this.checkDeviceType();

            if (deviceType !== window.app.deviceType) {
                app.vent.trigger('preChangeDeviceType', deviceType);

                window.app.deviceType = deviceType;

                app.vent.trigger('changeDeviceType', deviceType);
            }
        }, 100));
    };

    isSafari() {
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent && !navigator.userAgent.match('CriOS');
    };

    isHighDensity() {
        return ((
            window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), ' +
            'only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches ||
            window.matchMedia(
                'only screen and (-webkit-min-device-pixel-ratio: 1.3), ' +
                'only screen and (-o-min-device-pixel-ratio: 2.6/2), ' +
                'only screen and (min--moz-device-pixel-ratio: 1.3), ' +
                'only screen and (min-device-pixel-ratio: 1.3)'
            ).matches)) ||
            (window.devicePixelRatio && window.devicePixelRatio > 1.3
            ));
    };

    isIos() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    isIE() {
        if (navigator.userAgent.indexOf('MSIE') !== -1
            || navigator.appVersion.indexOf('Trident/') > 0) {
            return true;
        }

        return !!/Edge/.test(navigator.userAgent);
    };

    isTouch() {
        return 'ontouchstart' in document.documentElement;
    };

    checkDeviceType() {
        let indicator = document.querySelector('.u-StateIndicator');

        if (!indicator) {
            indicator = document.createElement('div');
            indicator.className = 'u-StateIndicator';
            document.body.appendChild(indicator);
        }

        let index = 1;

        // Для мобильного Firefox нужна отдельная проверка, т.к. при загрузке media query не срабатывает
        if (/Firefox/.test(navigator.userAgent) && /Mobile/.test(navigator.userAgent)) {
            index = 4;
        } else {
            index = parseInt(window.getComputedStyle(indicator)
                .getPropertyValue('z-index'), 10);
        }

        const deviceTypes = {
            1: 'desktop',
            2: 'laptop',
            3: 'tablet',
            4: 'mobile'
        };

        return deviceTypes[index] || 'desktop';
    };


    _capitalize(string) {
        return string.charAt(0)
            .toUpperCase() + string.slice(1);
    };
}
