import * as Backbone from 'backbone';
import * as _ from 'underscore';

export default class Router extends Backbone.Router {
    routes() {
        return {
            '': 'index',
            'about(/)': 'about',
            'contact-us(/)': 'contact',
            'tau(/)': 'university',
            'asu(/)': 'university',
            'technion(/)': 'university',
            'news(/)': 'news',
            'news/:id(/)': 'article',
            'coming-soon(/)': 'comingSoon'
        };
    };

    index() {
        this.importComponent('Index');
    }

    about() {
        this.importComponent('About');
    }

    contact() {
        this.importComponent('Contact');
    }

    university() {
        this.importComponent('University');
    }

    news() {
        this.importComponent('News');
    }

    article() {
        this.importComponent('Article');
    }

    comingSoon() {
        this.importComponent('ComingSoon');
    }

    notFound() {
        this.importComponent('NotFound');
    }

    importComponent(pageName) {
        import('front/pages/' + pageName + '/' + pageName).then(({default: Component}) => {
            this.activate(Component);
        });
    }

    activate(view, params) {
        params = params || {};

        // вьюха отрендерена на сервере
        if (!app.state.view) {
            params.server = true;

            app.state.view = new view(params);
            app.state.view.activate();

            return;
        }

        app.state.prevView = app.state.view;

        this.activateStandardLogic(view, params);
    };

    // активация перехода с полной перезагрузкой и лоадером
    activateStandardLogic(view, params) {
        const newParams = _.defaults({server: false, inAnimated: true}, params);
        const newView = new view(newParams);

        return Promise.all([app.state.view.playOut({
            view: newView
        }), newView.loadData()])
            .then(() => {
                app.els.$content.css({minHeight: app.els.$content.height()});

                return app.state.view.deactivate({destroy: true});
            })
            .then(() => {
                app.state.isServer = false;
                app.state.view = newView;
                app.state.view.activate(newParams)
                    .then(() => {
                        app.els.$content.css({minHeight: ''});
                        $(window)
                            .scrollTop(0);

                        return app.state.view.playIn({
                            view: newView
                        });
                    });
            });
    }

    start() {
        const is404 = app.els.$body.hasClass('Page404');
        const pushStateSupported = history && _.isFunction(history.pushState);
        Backbone.history.start({pushState: pushStateSupported, silent: is404, hashChange: false});

        if (is404) {
            this.notFound();
        }
    }
}
