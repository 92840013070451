import * as Backbone from 'backbone';
import * as _ from 'underscore';

import {ViewportEvents, CssClasses} from 'front/Constants/Constants';

import './TopNav.less';

const Selectors = {
    el: '.TopNav',
    logo: '.TopNav-logo',
    hamburger: '.TopNav-hamburger',
    hamburgerClick: '.TopNav-hamburgerClick',
    menuItem: '.TopNav-menuItem'
};

const BindFunctions = [
    'onHamburgerClick',
    'collapseHamburger'
];

export default class TopNav extends Backbone.View {
    events() {
        return {
            [`${ViewportEvents.click} ${Selectors.logo}`]: this.onLogoClick,
            [`${ViewportEvents.click} ${Selectors.hamburgerClick}`]: this.onHamburgerClick
        };
    }

    constructor() {
        super({el: Selectors.el});
        _.bindAll(this, BindFunctions);
        this.isMenuOpened = false;
    }

    collapseHamburger() {
        this.isMenuOpened = false;
        this.$(Selectors.hamburger)
            .removeClass(CssClasses.active);
    }

    onHamburgerClick() {
        this.isMenuOpened = !this.isMenuOpened;
        this.$(Selectors.hamburger)
            .toggleClass(CssClasses.active, this.isMenuOpened);
        window.app.views.topNavMenu.toggleMenu(this.isMenuOpened);
    }

    setActiveRoute(activeItemId) {
        const $activeItem = this.$(`${Selectors.menuItem}[data-id=${activeItemId}]`);
        $activeItem.addClass(CssClasses.active);
    }

    onLogoClick() {

    }
}
